import React from 'react'

export const Tab = (props) => {
    return (
        <li className={"tabs-title" + (props.isActive ? ' is-active' : '')} onClick={(event) => {
            event.preventDefault();
            props.onClick(props.tabIndex);
        }}>
            <a className={`tab-link`} aria-selected={props.isActive ? 'true' : ''}>
                <i className={`tab-icon ${props.iconClassName}`}/>{props.label}
            </a>
        </li>
    )
}

export class Tabs extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            activeTabIndex: this.props.defaultActiveTabIndex ? this.props.defaultActiveTabIndex : 0
        };
        this.handleTabClick = this.handleTabClick.bind(this);
    }

    // Toggle currently active tab
    handleTabClick(tabIndex) {
        this.setState({
            activeTabIndex: tabIndex === this.state.activeTabIndex ? this.props.defaultActiveTabIndex : tabIndex
        });
    }

    // Encapsulate <Tabs/> component API as props for <Tab/> children
    renderChildrenWithTabsApiAsProps() {
        return React.Children.map(this.props.children, (child, index) => {
            const isActive = index === this.state.activeTabIndex;
            return  React.cloneElement(child, {
                    onClick: this.handleTabClick,
                    tabIndex: index,
                    isActive: isActive
                })
        });
    }

    // Render current active tab content
    renderActiveTabContent() {
        const {children} = this.props;
        const {activeTabIndex} = this.state;
        if (children[activeTabIndex]) {
            return children[activeTabIndex].props.children;
        }
    }

    render() {
        return <div className="tabs-wrapper">
                <ul className="tabs">
                    {this.renderChildrenWithTabsApiAsProps()}
                </ul>
                <div className="tabs-content">
                    <div className="tabs-panel is-active">
                    {this.renderActiveTabContent()}
                    </div>
                </div>

            </div>
    }
};