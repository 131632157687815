import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { Tabs, Tab } from '../components/Tabs'
import { graphql } from 'gatsby'
import Layout from '../layouts/index'

class WhyMagento2 extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    const calloutStyle = {
      background: "url('pages/magento-2-header.jpg') no-repeat #17523c",
      backgroundSize: 'cover',
    }

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={'Why Magento 2? | ' + siteTitle} />

          <div className="pagehead">
            <div className="pagehead__wrap black small" />
          </div>

          <div className="callout blue" style={calloutStyle}>
            <div className="grid-container">
              <div className="grid-x">
                <div className="medium-8 text-center medium-offset-2 cell">
                  <h1>Why Magento 2?</h1>
                  <h4>
                    What makes Magento 2 the best choice for your business?
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="callout white">
            <div className="grid-container">
              <div className="grid-x">
                <Tabs defaultActiveTabIndex={0}>
                  <Tab
                    iconClassName={'icon-class-0'}
                    linkClassName={'link-class-0'}
                    label="Improved performance"
                  >
                    <p>
                      Compared to Magento 1, merchants can process up to 39%
                      more orders per hour, and provides up to 51% faster guest
                      checkout response times with Magento 2. It also delivers
                      nearly instant response times for catalog pages, while
                      enabling up to 66% faster add-to-cart server response
                      times.
                    </p>
                  </Tab>
                  <Tab
                    iconClassName={'icon-class-1'}
                    linkClassName={'link-class-1'}
                    label="Responsive design"
                  >
                    <p>
                      With Magento 2, there are only two steps to complete the
                      checkout process. Customer’s no longer have to register to
                      checkout, but can do so afterwards, removing barriers to
                      conversions. In addition, shipping rates load
                      automatically once an address is entered.
                    </p>
                  </Tab>
                  <Tab
                    iconClassName={'icon-class-1'}
                    linkClassName={'link-class-1'}
                    label="Checkout experience"
                  >
                    <p>
                      Poor performance and bad user experiences on mobile
                      devices frustrate consumers and makes them less likely to
                      purchase from your store. Magento 2 fully embraces
                      responsive best practices with its modern frontend
                      architecture. It is designed in a mobile-first context,
                      ensuring mobile users have a fast and intuitive
                      experience.
                    </p>
                  </Tab>
                  <Tab
                    iconClassName={'icon-class-1'}
                    linkClassName={'link-class-1'}
                    label="Backend"
                  >
                    <p>
                      With the Magento 2, the admin dashboard is now
                      tablet-friendly, streamlined, and easier to navigate. In
                      addition, with Magento 1, any changes to the admin portion
                      could only be done by a developer—now certain changes can
                      be made without that added expense or timeframe.
                    </p>
                  </Tab>
                  <Tab
                    iconClassName={'icon-class-1'}
                    linkClassName={'link-class-1'}
                    label="Features"
                  >
                    <p>
                      Magento Inc is investing all of their development efforts
                      on adding new features to Magento 2, not Magento
                      1.Meaning, if you are not on Magento 2, you will be
                      lagging behind your competitors when it comes to new
                      technologies.
                    </p>
                  </Tab>
                  <Tab
                    iconClassName={'icon-class-1'}
                    linkClassName={'link-class-1'}
                    label="Easily upgradeable"
                  >
                    <p>
                      With Magento 1, an upgrade from one version of 1.X to a
                      higher version resulted in a large, time-consuming
                      project. Though the jump from Magento 1 to Magento 2 is
                      significant, upgrades from that point will be much easier,
                      and therefore more cost-effective.
                    </p>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>

          <div className="callout magento">
            <div className="grid-x">
              <div className="text-center small-10 small-offset-1 medium-6 medium-offset-3">
                <h2>
                  <strong>Considering Magento 2?</strong>
                </h2>
                <h5>
                  <strong>
                    Just talk to us & learn how we can help your business grow.
                  </strong>
                </h5>
                <Link className="button hollow" to="/contact">
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default WhyMagento2

export const pageQuery = graphql`
  query WhyMagento2Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
